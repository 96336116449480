.panel {
  border-left: 2px solid #e6e8ed;
  height: 100%;
  padding-left: 3%;
}

.title {
  font-family: Graphik Web, Arial, Calibri, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
