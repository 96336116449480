.option {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  column-gap: 20px;
  margin-bottom: 4px;
}

.option:hover {
  background: #e6e8ed;
  border-radius: 2px;
  outline: 0;
}

.uniform-response {
  width: 18px;
  display: flex;
  align-items: center;
}

.custom-choice {
  border: none;
  background-color: transparent;
  font-family: Graphik Web, Arial, Calibri, sans-serif;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.radio {
  accent-color: black;
  border: none;
  zoom: 1.5;
  cursor: pointer;
}

label {
  width: 100%;
  cursor: pointer;
}
.selected {
  background-color: #e6e8ed;
}
.selected .custom-choice {
  font-weight: 600;
}
