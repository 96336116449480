.grid-wrapper {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
}
