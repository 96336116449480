.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  border-radius: 6px;
  min-width: 20%;
  height: 60%;
  background-color: white;
  padding: 15px;
}

.modal-title {
  display: block;
  font-size: larger;
  align-self: flex-start;
  margin: 5px 0 5px 0;
}

.modal-description {
  display: block;
  font-size: small;
  align-self: flex-start;
}

.choices {
  align-items: center;
  overflow-y: auto;
  height: 80%;
}

.call-to-action-btns {
  height: 10%;
}
