.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.logo {
  margin: 20px 0 40px;
  height: 25px;
}
