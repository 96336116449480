.row {
  background-color: transparent;
  display: table-row;
  vertical-align: inherit;
  border-top: 1px solid #e6e8ed;
  border-bottom: 1px solid #e6e8ed;
  height: 57px;
}

.row:hover {
  background-color: rgb(236, 236, 240);
}
