.header-cell {
  vertical-align: middle;
  min-width: 75px;
}

.header-wrapper {
  position: relative;
  width: 100%;
}

.header-input {
  border: none;
  background-color: transparent;
  color: #333;
  font-weight: 500;
  text-align: center;
  width: 80%;
}

.remove-btn-wrapper {
  display: none;
  position: absolute;
  right: 0;
  width: 24px;
}

.header-wrapper:hover .remove-btn-wrapper {
  display: inline;
}
